import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import LandingPage from './components/LandingPage';
import PrivacyPage from './components/PrivacyPage';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="privacy-page" element={<PrivacyPage />}>
        </Route>
      </Routes>
    </BrowserRouter> 
  );
}

export default App;
